.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}


/* styles/StacksContainer.css */

.stacks-container {
	margin-top: 20px;
  }
  
  .stacks-title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
  }
  
  .stacks-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 columns for laptops */
	gap: 20px; /* spacing between stacks */
  }
  
  @media (max-width: 1024px) {
	.stacks-grid {
	  grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
	}
  }
  
  @media (max-width: 768px) {
	.stacks-grid {
	  grid-template-columns: repeat(1, 1fr); /* 1 column for mobile */
	}
  }
  
  
  /* styles/Stack.css */
  
  .stack-item {
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease;
  }
  
  .stack-item:hover {
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .stack-logo {
	max-width: 100%;
	height: auto;
	margin-bottom: 10px;
  }
  
  .stack-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
  }
  
  .stack-description {
	font-size: 14px;
	color: #666;
  }
  