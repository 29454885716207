@import "../../../data/styles.css";


.contact-form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .contact-form h3 {
    color: #333;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #4a5568;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #cbd5e0;
    border-radius: 0.25rem;
    transition: border-color 0.2s ease-in-out;
  }
  
  .contact-form input[type="text"]:focus,
  .contact-form input[type="email"]:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #4299e1;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .contact-form button:hover {
    background-color: #3182ce;
  }
  