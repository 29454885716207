/* styles/Stack.css */
@import "../../../data/styles.css";

.stack-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.stack-item:hover {
  transform: translateY(-5px);
}

.stack-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stack-description {
  font-size: 14px;
  color: #666666;
}

.stack-logo {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
}
